import React from "react";
import ShareIcon from "../../resources/images/eventv2/share_icon.svg";
import BackIcon from "../../resources/images/eventv2/back_icon.svg";
import "./Navbar.css";

export default function Navbar() {
  return (
    <div className="navbar_container">
      <img src={BackIcon} alt="back_icon" style={{ cursor: "pointer" }} />
      <p className="logo_text">THRIVIA</p>
      <img src={ShareIcon} alt="share_icon" style={{ cursor: "pointer" }} />
    </div>
  );
}
