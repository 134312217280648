import React, { useState, useEffect } from "react";
import "./Auth.css";
import InputBoxWithLabel from "./InputBoxWithLabel";
import ChevronLeftIcon from "../../resources/images/chevron-left.svg";
import { useMediaQuery } from "@mui/material";
import ThriviaLogo from "../../resources/images/thrivia_logo.png";
import MobLogin from "../../resources/images/mob-login.png";
import SocialLogin from "./SocialLogin";
import SocialLoginReact from "./SocialLoginReact";

export default function LoginFlow({
  showNav,
  type,
}: {
  showNav?: boolean;
  type?: "explorer" | "creator";
}) {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [currentState, setCurrentState] = useState(0);
  const [password, setPassword] = useState({
    value: "",
    helperText: "",
    error: false,
  });
  const [confirmPassword, setConfirmPassword] = useState({
    value: "",
    helperText: "",
    error: false,
  });
  const [seconds, setSeconds] = useState(30);
  const [canResend, setCanResend] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (seconds > 0 && !canResend) {
      timer = setInterval(() => {
        setSeconds((prev) => prev - 1);
      }, 1000);
    } else if (seconds === 0) {
      setCanResend(true);
    }
    return () => clearInterval(timer);
  }, [seconds, canResend]);

  // ... existing code ...

  const handleResendOTP = () => {
    setSeconds(30);
    setCanResend(false);
    // Add your OTP resend logic here
  };

  const actionButton = (text: string, onClick: () => void) => {
    return (
      <button
        className="action_button"
        style={{ marginTop: 25 }}
        onClick={onClick}
      >
        {text}
      </button>
    );
  };

  const backButton = () => {
    return (
      <div
        style={{
          display: "flex",
          cursor: "pointer",
          gap: 8,
          alignItems: "center",
        }}
        onClick={() => setCurrentState(currentState - 1)}
      >
        <img src={ChevronLeftIcon} alt="back" />
        <p className="back_button_signup">Back</p>
      </div>
    );
  };

  const topSection1 = () => {
    return (
      <div>
        {/* {
          <div
            style={{
              marginLeft: isMobile ? 0 : -25,
              marginBottom: isMobile ? 14 : 50,
              marginTop: isMobile ? 14 : 0,
              width: "fit-content",
            }}
          >
            {currentState > 0 && backButton()}
            {currentState === 0 && <div style={{ height: 24 }}></div>}
          </div>
        } */}

        <p className="welcome_text">Welcome Back</p>
        <p className="welcome_sub_text">Explore best experiences with us</p>
        <p className="welcome_sub_text">
          Need an account?{" "}
          <span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() =>
              type === "explorer"
                ? (window.location.href = "/signup-explorer")
                : (window.location.href = "/signup-creator")
            }
          >
            Sign up
          </span>
        </p>
      </div>
    );
  };

  const inputEmail = () => {
    const handleLogin = () => {
      // setCurrentState(1);
      window.location.href = "/discover";
    };
    return (
      <div>
        <InputBoxWithLabel
          label="Phone or Email"
          margintop={0}
          type="text"
          inputProps={{}}
          error={false}
          helperText=""
        />
        <InputBoxWithLabel
          label="Password"
          margintop={14}
          type="password"
          inputProps={{}}
          error={false}
          helperText=""
        />
        <p
          className="forgot_password_text"
          style={{ marginTop: 14 }}
          onClick={() => {
            setCurrentState(1);
          }}
        >
          Forgot Password?
        </p>
        {actionButton("Login", handleLogin)}
      </div>
    );
  };

  const topSection2 = () => {
    return (
      <div>
        {
          <div
            style={{
              marginLeft: isMobile ? 0 : -25,
              marginBottom: isMobile ? 14 : 50,
              marginTop: isMobile ? 14 : 0,
              width: "fit-content",
            }}
          >
            {currentState > 0 && backButton()}
            {currentState === 0 && <div style={{ height: 24 }}></div>}
          </div>
        }

        <p className="welcome_text">Forgot Password</p>
      </div>
    );
  };

  const topSection3 = () => {
    return (
      <div>
        {
          <div
            style={{
              marginLeft: isMobile ? 0 : -25,
              marginBottom: isMobile ? 14 : 50,
              marginTop: isMobile ? 14 : 0,
              width: "fit-content",
            }}
          >
            {currentState > 0 && backButton()}
            {currentState === 0 && <div style={{ height: 24 }}></div>}
          </div>
        }

        <p className="welcome_text">OTP Verification</p>
        <div
          className="otp_verification_text"
          style={{ marginTop: 27, marginBottom: -23 }}
        >
          <p style={{ opacity: 0.48 }}>
            Enter the 6-digit OTP we have sent to your registered phone
          </p>
        </div>
      </div>
    );
  };

  const inputForgotPassword = () => {
    const handleSendOTP = () => {
      setCurrentState(2);
    };
    return (
      <div>
        <InputBoxWithLabel
          label="Phone or Email"
          margintop={0}
          type="text"
          inputProps={{}}
          error={false}
          helperText=""
        />
        {actionButton("Send OTP", handleSendOTP)}
      </div>
    );
  };

  const inputOTP = () => {
    const handleVerifyOTP = () => {
      setCurrentState(3);
    };
    return (
      <div>
        <InputBoxWithLabel
          label="Enter OTP"
          margintop={0}
          type="text"
          inputProps={{ maxLength: 6 }}
          error={false}
          helperText=""
        />
        {actionButton("Verify", handleVerifyOTP)}
        <div
          style={{
            display: "flex",
            gap: 4,
            justifyContent: "center",
            marginTop: 18,
          }}
        >
          <p className="didnt_receive_text">Didn't receive OTP?</p>
          {canResend ? (
            <p
              className="wait_text"
              style={{ cursor: "pointer" }}
              onClick={handleResendOTP}
            >
              Resend OTP
            </p>
          ) : (
            <p className="wait_text">Wait for {seconds}s</p>
          )}
        </div>
      </div>
    );
  };

  const setPasswordState = () => {
    const handleConfirm = () => {
      // setCurrentState(3);
      if (password.value !== confirmPassword.value) {
        setConfirmPassword({
          ...confirmPassword,
          error: true,
        });
        setPassword({
          ...password,
          error: true,
          helperText: "Passwords do not match",
        });
      } else {
        window.location.href = "/discover";
      }
    };
    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setPassword({ value: e.target.value, helperText: "", error: false });
      if (confirmPassword.error) {
        setConfirmPassword({
          ...confirmPassword,
          error: false,
          helperText: "",
        });
      }
    };
    const handleConfirmPasswordChange = (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      setConfirmPassword({
        value: e.target.value,
        helperText: "",
        error: false,
      });
      if (password.error) {
        setPassword({
          ...password,
          error: false,
          helperText: "",
        });
      }
    };
    return (
      <div>
        {/* {inputBoxWithLabel(
          "New Password",
          14,
          "password",
          {
            value: password.value,
            onChange: handlePasswordChange,
          },
          password.error,
          password.helperText
        )} */}

        <InputBoxWithLabel
          label="New Password"
          margintop={14}
          type="password"
          inputProps={{
            value: password.value,
            onChange: handlePasswordChange,
          }}
          error={password.error}
          helperText={password.helperText}
        />
        <InputBoxWithLabel
          label="Confirm Password"
          margintop={14}
          type="password"
          inputProps={{
            value: confirmPassword.value,
            onChange: handleConfirmPasswordChange,
          }}
          error={confirmPassword.error}
          helperText=""
        />
        {actionButton("Confirm", handleConfirm)}
      </div>
    );
  };

  const topSection4 = () => {
    return (
      <div>
        <div
          style={{
            marginLeft: isMobile ? 0 : -25,
            marginBottom: isMobile ? 14 : 50,
            marginTop: isMobile ? 14 : 0,
            width: "fit-content",
          }}
        >
          {backButton()}
        </div>
        <p className="welcome_text">Set Password</p>
      </div>
    );
  };
  const mobNav = () => {
    return (
      <div
        style={{
          paddingTop: 24,
          marginLeft: 14,
          display: "flex",
          alignItems: "center",
          gap: 20,
          cursor: "pointer",
        }}
      >
        <img src={ThriviaLogo} alt="Logo" />
        <p className="nav_logo_p">Thrivia</p>
      </div>
    );
  };
  const nav = () => {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          paddingTop: 38,
          marginLeft: 74,
          display: "flex",
          alignItems: "center",
          gap: 20,
          cursor: "pointer",
        }}
      >
        <img src={ThriviaLogo} alt="Logo" />
        <p className="nav_logo_p">Thrivia</p>
      </div>
    );
  };
  return (
    <div style={{ flex: 1, marginTop: isMobile ? 14 : 165 }}>
      {!isMobile && nav()}
      {isMobile && mobNav()}
      {isMobile && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "24px 0px",
          }}
        >
          <img src={MobLogin} alt="Login" />
        </div>
      )}
      <div
        style={{
          marginLeft: isMobile ? 14 : 109,
          marginRight: isMobile ? 14 : 109,
        }}
      >
        {currentState === 0 && topSection1()}
        {currentState === 1 && topSection2()}
        {currentState === 2 && topSection3()}
        {currentState === 3 && topSection4()}
        <div style={{ marginTop: isMobile ? 20 : 50 }}>
          {currentState === 0 && inputEmail()}
          {currentState === 1 && inputForgotPassword()}
          {currentState === 2 && inputOTP()}
          {currentState === 3 && setPasswordState()}
        </div>
        <SocialLogin />
      </div>
    </div>
  );
}
