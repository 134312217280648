import React from "react";
import "./CreateEvent.css";
import CopyIcon from "../../resources/images/create_event/copy_icon.svg";
import { insightDivider } from "./Insights";
import EmbedButtonIcon from "../../resources/images/create_event/embed_button_icon.svg";
import EmbedPageIcon from "../../resources/images/create_event/embed_page_icon.svg";
import PinkCheckIcon from "../../resources/images/create_event/pink_check_icon.svg";
import CircleCrossIcon from "../../resources/images/create_event/circle_cross_icon.svg";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { solarizedlight } from "react-syntax-highlighter/dist/esm/styles/prism";

export default function More() {
  const moreHeader = () => {
    return (
      <div className="more_header_container">
        <div className="insight_header_container">
          <p className="insight_header_text">Clone Event</p>
        </div>
        <p className="insight_header_subtext" style={{ marginTop: 5 }}>
          Create a new event with the same information as this one. Everything
          except the guest list and event blasts will be copied over.
        </p>
        <button className="more_button" style={{ marginTop: 21 }}>
          <img src={CopyIcon} alt="copy_icon" />
          Clone Event
        </button>
      </div>
    );
  };

  const eventPageUrl = () => {
    return (
      <div className="event_page_url_container">
        <p className="insight_header_text">Event Page URL</p>
        <p className="insight_header_subtext" style={{ marginTop: 5 }}>
          When you choose a new URL, the current one will no longer work. Do not
          change your URL if you have already shared the event.
        </p>
        <div className="event_page_url_input_container">
          <div className="event_page_url_input_container_left">
            <p className="event_page_url_input_container_left_text">
              thrivia.io/
            </p>
          </div>
          <input
            className="event_page_url_input_container_right"
            value="wywu88Q"
          />
          <button className="event_page_url_input_container_right_button">
            Update
          </button>
        </div>
      </div>
    );
  };

  const embedEvent = () => {
    const embedButton = (top: number, icon: string, text: string) => {
      return (
        <div className="embed_button_container" style={{ marginTop: top }}>
          <img src={icon} alt="embed_icon" />
          <p className="embed_button_text">{text}</p>
          <img src={PinkCheckIcon} alt="pink_check_icon" />
        </div>
      );
    };
    return (
      <div className="embed_event_container">
        <p className="insight_header_text">Embed Event</p>
        <p className="insight_header_subtext" style={{ marginTop: 5 }}>
          Have your own site? Embed the event to let visitors know about it.
        </p>
        {embedButton(19, EmbedButtonIcon, "Embed as button")}
        {embedButton(5, EmbedPageIcon, "Embed Event Page")}
        <p
          className="embed_event_subtext"
          style={{ marginTop: 33, marginBottom: 19 }}
        >
          Paste the following HTML code snippet to your page:
        </p>
        <div className="embed_event_code_container">
          <SyntaxHighlighter
            language="html"
            style={solarizedlight}
            customStyle={{ backgroundColor: "#fff", margin: 0 }}
          >
            {`<a
  href="https://thrivia.io/wywu88Q"
  className="thrivia-button"
  data-thrivia-action="checkout"
  data-thrivia-event-id="evt-HWEqESKKCwbSH3E"
>
  Register for Event
</a>

<script id="thrivia-checkout" src="https://embed.thrivia.io/checkout-button.js"></script>`}
          </SyntaxHighlighter>
        </div>
        <p
          className="embed_event_subtext"
          style={{ marginTop: 15, marginBottom: 23 }}
        >
          This gives you the following button. Click it to see it in action!
        </p>
        <div className="preview_button_container">
          <button className="preview_button">Register for event</button>
        </div>
        <p className="preview_info_text">
          If you want to use your own styling for the button, simply remove the
          <span className="preview_info_text_span_1"> thrivia-button</span>{" "}
          class from the snippet above.
          <br /> <br />
          For advanced usage, check out our{" "}
          <span className="preview_info_text_span_2">
            {" "}
            example code and documentation.
          </span>
        </p>
      </div>
    );
  };

  const cancelEvent = () => {
    return (
      <div className="cancel_event_container">
        <p className="insight_header_text">Cancel Event</p>
        <p className="insight_header_subtext" style={{ marginTop: 5 }}>
          Cancel and permanently delete this event. This operation cannot be
          undone. If there are any registered guests, we will notify them that
          the event has been cancelled.
        </p>
        <button className="cancel_event_button">
          <img src={CircleCrossIcon} alt="circle_cross_icon" />
          Cancel Event
        </button>
      </div>
    );
  };
  return (
    <div>
      {moreHeader()}
      {insightDivider(22, 21)}
      {eventPageUrl()}
      {insightDivider(28, 21)}
      {embedEvent()}
      {insightDivider(25, 21)}
      {cancelEvent()}
    </div>
  );
}
