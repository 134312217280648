import React from "react";
import "./CreateEvent.css";
import { insightDivider } from "./Insights";
import InviteIcon from "../../resources/images/create_event/invite_icon.svg";
import ShareRedIcon from "../../resources/images/create_event/share_red_icon.svg";
import BlastIcon from "../../resources/images/create_event/blast_icon.svg";
import FbIcon from "../../resources/images/create_event/fb_icon.svg";
import TwIcon from "../../resources/images/create_event/tw_icon.svg";
import LiIcon from "../../resources/images/create_event/li_icon.svg";
import WaIcon from "../../resources/images/create_event/wa_icon.svg";
import MsgIcon from "../../resources/images/create_event/msg_icon.svg";
import MerIcon from "../../resources/images/create_event/mer_icon.svg";
import LocationIcon from "../../resources/images/create_event/location_icon.svg";
import ScanIcon from "../../resources/images/create_event/scan_icon.svg";
import PlusIcon from "../../resources/images/create_event/plus_icon.svg";
import ExpandIcon from "../../resources/images/create_event/expand_icon.svg";
import TestIcon from "../../resources/images/create_event/test_icon.svg";
import ArrowRightIcon from "../../resources/images/create_event/arrow_right_icon.svg";
import TestProfileIcon from "../../resources/images/create_event/test_profile_icon.svg";
import EditIcon from "../../resources/images/create_event/edit_icon.svg";

export default function Guests() {
  const guestOptions = () => {
    const opts = [
      {
        title: "Invite Guests",
        icon: InviteIcon,
        background: "#E2E7FE",
      },
      {
        title: "Send a Blast",
        icon: BlastIcon,
        background: "#F2E5FA",
      },
      {
        title: "Share Event",
        icon: ShareRedIcon,
        background: "#FEE1EC",
      },
    ];
    return (
      <div className="guest_options_container">
        {opts.map((opt, index) => (
          <div className="guest_options_buttons">
            <div
              className="guest_options_buttons_icon_container"
              style={{ background: opt.background }}
            >
              <img src={opt.icon} alt="invite_icon" />
            </div>
            <p className="guest_options_buttons_text">{opt.title}</p>
          </div>
        ))}
      </div>
    );
  };
  const topSection = () => {
    return (
      <div className="guests_top_section">
        {guestOptions()}
        <div className="event_preview">
          <div className="event_preview_container">
            <div className="event_copy_container">
              <p className="event_copy_link">thrivia.io/duhu38n</p>
              <p className="event_copy_link">COPY</p>
            </div>
          </div>
          <div className="event_details">
            <div className="event_share_container">
              <p className="event_share_text">Event Details</p>
              <div className="event_share_icon_container">
                <img src={FbIcon} alt="fb_icon" />
                <img src={TwIcon} alt="tw_icon" />
                <img src={LiIcon} alt="li_icon" />
                <img src={WaIcon} alt="wa_icon" />
                <img src={MsgIcon} alt="msg_icon" />
                <img src={MerIcon} alt="mer_icon" />
              </div>
            </div>
            <div className="event_details_container">
              <p className="event_details_text">When and Where</p>
              <div className="event_details_container_inner">
                <div className="event_details_container_inner_left">
                  <p className="event_details_container_inner_left_text">DEC</p>
                  <p className="event_details_container_inner_left_text_primary">
                    10
                  </p>
                </div>
                <div className="event_details_container_inner_right">
                  <p className="event_details_container_inner_right_text_primary">
                    Wednesday 25 Dec
                  </p>
                  <p className="event_details_container_inner_right_text">
                    19:30 - 26 Dec, 20:30 GMT+5:30
                  </p>
                </div>
              </div>
              <div className="event_details_container_inner">
                <div className="event_details_container_inner_left">
                  <img src={LocationIcon} alt="location_icon" />
                </div>
                <div className="event_details_container_inner_right">
                  <p className="event_details_container_inner_right_text_primary">
                    Hasanpur
                  </p>
                  <p className="event_details_container_inner_right_text">
                    Uttar Pradesh 244241, India
                  </p>
                </div>
              </div>
              <p
                className="event_details_container_inner_right_text"
                style={{ fontWeight: 700, marginTop: 20 }}
              >
                The address is shown publicly on the event page.
              </p>
              <div style={{ marginTop: 18 }}>
                <button className="checkin_button">
                  <img src={ScanIcon} alt="scan_icon" />
                  <p className="checkin_button_text">Check In Guests</p>
                </button>
              </div>
              <div className="checkin_button_container">
                <button className="checkin_button">
                  <p className="checkin_button_text">Edit Event</p>
                </button>
                <button className="checkin_button">
                  <p className="checkin_button_text">Edit Event</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const invitations = () => {
    const recentGuests = [
      {
        name: "John Doe",
        email: "john.doe@example.com",
        icon: TestIcon,
      },
      {
        name: "Utkarsh Koushik",
        email: "utkarsh@example.com",
        icon: TestIcon,
      },
      {
        name: "John Doe",
        email: "john.doe@example.com",
        icon: TestIcon,
      },
    ];
    return (
      <div className="invitations_container">
        <div className="invitations_container_heading">
          <p className="invitations_container_heading_text">Invitations</p>
          <div className="tickets_container_header_button">
            <img src={PlusIcon} alt="plus_icon" />
            <p className="tickets_container_header_button_text">
              Invite Guests
            </p>
          </div>
        </div>
        <div className="invitations_details_container">
          <div>
            <p className="number_of_guests" style={{ marginLeft: 8 }}>
              1<span className="number_of_guests_subtext">/1</span>
            </p>
            <p className="number_of_guests_text">Invitation Accepted </p>
          </div>
          <div className="email_opened_container">
            <img
              src={ExpandIcon}
              alt="expand_icon"
              className="email_opened_icon"
            />
            <p className="email_opened_text">
              1 Email Opened <br />0 Declined
            </p>
          </div>
        </div>
        <div className="recent_guests_container">
          <div className="recent_guests_container_inner">
            <p className="recent_guests_container_text">RECENTLY ACCEPTED</p>
          </div>
          <div className="recent_guests_divider" />
          <div className="recent_guests_container_inner_2">
            {recentGuests.map((guest, index) => (
              <div
                className="recent_guests_container_inner_guest"
                key={index + "recent"}
              >
                <img
                  src={guest.icon}
                  alt="guest_icon"
                  className="recent_guests_container_inner_guest_icon"
                />
                <p
                  className="recent_guests_container_inner_guest_text"
                  style={{ flex: 1 }}
                >
                  {guest.name}
                </p>
                <p className="recent_guests_container_inner_guest_email">
                  {guest.email}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const guests = () => {
    const glance = () => {
      return (
        <div style={{ marginTop: 20 }}>
          <div className="glance_container_content">
            <div className="glance_container_content_item">
              <p
                className="glance_container_content_item_header"
                style={{ color: "#3CBD2D" }}
              >
                1{" "}
                <span
                  className="glance_container_content_item_header_secondary"
                  style={{ color: "#3CBD2D" }}
                >
                  guest
                </span>
              </p>
              <p className="glance_container_content_item_secondary">
                cap{" "}
                <span className="glance_container_content_item_secondary_secondary">
                  20
                </span>
              </p>
            </div>
            <div className="glance_container_content_line">
              <div
                className="glance_container_content_line_item"
                style={{
                  width: "calc(100/20 * 1%)",
                  backgroundColor: "#3CBD2D",
                }}
              ></div>
            </div>
            <div className="glance_container_going_text_container">
              <div
                className="glance_container_going_text_dot"
                style={{ backgroundColor: "#3CBD2D" }}
              />
              <p
                className="glance_container_going_text_primary"
                style={{ color: "#3CBD2D" }}
              >
                1 going
              </p>
            </div>
          </div>
        </div>
      );
    };
    return (
      <div>
        <p className="invitations_container_heading_text">Guests</p>
        {glance()}
      </div>
    );
  };

  const recentRegistrations = () => {
    const guestListFilter = () => {
      const guests = [
        {
          name: "John Doe",
          registerTime: "12:00 PM",
          status: "Going",
          email: "john.doe@example.com",
          profilePic: TestProfileIcon,
        },
        {
          name: "John Doe",
          registerTime: "12:00 PM",
          status: "Going",
          email: "john.doe@example.com",
          profilePic: TestProfileIcon,
        },
        {
          name: "John Doe",
          registerTime: "12:00 PM",
          status: "Going",
          email: "john.doe@example.com",
          profilePic: TestProfileIcon,
        },
        {
          name: "John Doe",
          registerTime: "12:00 PM",
          status: "Going",
          email: "john.doe@example.com",
          profilePic: TestProfileIcon,
        },
      ];
      return (
        <div className="recent_registrations_container_inner">
          {guests.map((guest, index) => (
            <div className="guest_list_filter_item_container">
              <img
                src={guest.profilePic}
                alt="guest_profile_pic"
                className="guest_list_filter_item_container_profile_pic"
              />
              <div className="guest_list_filter_item_container_right">
                <div className="guest_list_filter_item_container_right_top">
                  <div>
                    <p className="guest_list_filter_item_container_right_top_name">
                      {guest.name}
                    </p>
                    <p className="guest_list_filter_item_container_right_email">
                      {guest.email}
                    </p>
                  </div>
                  <div className="guest_list_filter_item_container_right_top_right">
                    <p className="recent_registrations_container_inner_time">
                      {guest.registerTime}
                    </p>
                    <div className="guest_list_filter_item_container_right_top_status">
                      <p className="guest_list_filter_item_container_right_top_status_text">
                        {guest.status}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    };
    return (
      <div className="recent_registrations_container">
        <div className="invitations_container_heading">
          <p className="invitations_container_heading_text">
            Recent Registrations
          </p>
          <div className="tickets_container_header_button">
            <p className="tickets_container_header_button_text">All Guests</p>
            <img src={ArrowRightIcon} alt="arrow_right_icon" />
          </div>
        </div>
        {guestListFilter()}
      </div>
    );
  };

  const hostList = () => {
    const hosts = [
      {
        id: "host-1",
        name: "John Doe",
        type: "Creator",
        profilePic: TestIcon,
      },
      {
        id: "host-2",
        name: "John Doe",
        type: "Creator",
        profilePic: TestIcon,
      },
      {
        id: "host-3",
        name: "John Doe",
        type: "Creator",
        profilePic: TestIcon,
      },
    ];
    return (
      <div className="host_list_container">
        <div className="invitations_container_heading">
          <p className="invitations_container_heading_text">Hosts</p>
          <div className="tickets_container_header_button">
            <img src={PlusIcon} alt="plus_icon" />
            <p className="tickets_container_header_button_text">Add Host</p>
          </div>
        </div>
        <p className="host_list_container_text">
          Add hosts, special guests, and event managers.
        </p>
        <div className="host_list">
          {hosts.map((host, index) => (
            <div className="host_list_item" key={index + "host"}>
              <img
                src={host.profilePic}
                alt="host_profile_pic"
                className="host_list_item_profile_pic"
              />
              <p className="host_list_item_name">{host.name}</p>
              <div className="guest_list_filter_item_container_right_top_status">
                <p className="guest_list_filter_item_container_right_top_status_text">
                  {host.type}
                </p>
              </div>
              <div className="host_list_item_edit_container">
                <img src={EditIcon} alt="edit_icon" />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  return (
    <div>
      {topSection()}
      {invitations()}
      {insightDivider(22, 22)}
      {guests()}
      {recentRegistrations()}
      {insightDivider(19, 23)}
      {hostList()}
    </div>
  );
}
