import React, { useRef } from "react";
import "./CreateEvent.css";
import InviteIcon from "../../resources/images/create_event/invite_icon.svg";
import CheckinIcon from "../../resources/images/create_event/checkin_icon.svg";
import PeopleIcon from "../../resources/images/create_event/people_icon.svg";
import DownloadIcon from "../../resources/images/create_event/download_icon.svg";
import OpenIcon from "../../resources/images/create_event/open_icon.svg";
import SearchIcon from "../../resources/images/create_event/search_icon.svg";
import FilterIcon1 from "../../resources/images/create_event/filter_icon_1.svg";
import FilterIcon2 from "../../resources/images/create_event/filter_icon_2.svg";
import ChevronDown from "../../resources/images/create_event/chevron_down.svg";
import TestProfileIcon from "../../resources/images/create_event/test_profile_icon.svg";

export default function OverView() {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const glance = () => {
    return (
      <div className="glance_container">
        <p className="glance_container_title_text">At a glance</p>
        <div className="glance_container_content">
          <div className="glance_container_content_item">
            <p className="glance_container_content_item_header">
              1{" "}
              <span className="glance_container_content_item_header_secondary">
                guest
              </span>
            </p>
            <p className="glance_container_content_item_secondary">
              cap{" "}
              <span className="glance_container_content_item_secondary_secondary">
                20
              </span>
            </p>
          </div>
          <div className="glance_container_content_line">
            <div
              className="glance_container_content_line_item"
              style={{ width: "calc(100/20 * 1%)" }}
            ></div>
          </div>
          <div className="glance_container_going_text_container">
            <div className="glance_container_going_text_dot" />
            <p className="glance_container_going_text_primary">1 going</p>
          </div>
        </div>
      </div>
    );
  };

  const guestOptions = () => {
    const opts = [
      {
        title: "Invite Guests",
        icon: InviteIcon,
        background: "#E2E7FE",
      },
      {
        title: "Check-in Guests",
        icon: CheckinIcon,
        background: "#E5F4E4",
      },
      {
        title: "Manage Guests",
        icon: PeopleIcon,
        background: "#F8EFE2",
      },
    ];
    return (
      <div className="guest_options_container">
        {opts.map((opt, index) => (
          <div className="guest_options_buttons">
            <div
              className="guest_options_buttons_icon_container"
              style={{ background: opt.background }}
            >
              <img src={opt.icon} alt="invite_icon" />
            </div>
            <p className="guest_options_buttons_text">{opt.title}</p>
          </div>
        ))}
      </div>
    );
  };

  const guestList = () => {
    return (
      <div className="guest_list_container">
        <div className="guest_list_container_header">
          <p className="guest_list_container_header_title">Guest List</p>
          <div className="guest_list_container_header_button">
            <img src={DownloadIcon} alt="download_icon" />
          </div>
          <div className="guest_list_container_header_button">
            <img src={OpenIcon} alt="open_icon" />
          </div>
        </div>
        <div className="guest_list_search_container">
          <img
            src={SearchIcon}
            alt="search_icon"
            style={{ cursor: "pointer" }}
            onClick={() => searchInputRef.current?.focus()}
          />
          <input
            type="text"
            placeholder="Search"
            className="guest_list_search_input"
            ref={searchInputRef}
          />
        </div>
        <div className="guest_list_filter_container">
          <div className="guest_list_filter_button">
            <img src={FilterIcon1} alt="filter_icon_1" />
            <p className="guest_list_filter_button_text">All Guests</p>
            <img src={ChevronDown} alt="chevron_down" />
          </div>
          <div className="guest_list_filter_button">
            <img src={FilterIcon2} alt="filter_icon_1" />
            <p className="guest_list_filter_button_text">Register Time</p>
            <img src={ChevronDown} alt="chevron_down" />
          </div>
        </div>
      </div>
    );
  };

  const guestListFilter = () => {
    const guests = [
      {
        name: "John Doe",
        registerTime: "12:00 PM",
        status: "Going",
        email: "john.doe@example.com",
        profilePic: TestProfileIcon,
      },
      {
        name: "John Doe",
        registerTime: "12:00 PM",
        status: "Going",
        email: "john.doe@example.com",
        profilePic: TestProfileIcon,
      },
      {
        name: "John Doe",
        registerTime: "12:00 PM",
        status: "Going",
        email: "john.doe@example.com",
        profilePic: TestProfileIcon,
      },
      {
        name: "John Doe",
        registerTime: "12:00 PM",
        status: "Going",
        email: "john.doe@example.com",
        profilePic: TestProfileIcon,
      },
    ];
    return (
      <div className="filtered_guest_list_filter_container">
        {guests.map((guest, index) => (
          <div className="guest_list_filter_item_container">
            <img
              src={guest.profilePic}
              alt="guest_profile_pic"
              className="guest_list_filter_item_container_profile_pic"
            />
            <div className="guest_list_filter_item_container_right">
              <div className="guest_list_filter_item_container_right_top">
                <p className="guest_list_filter_item_container_right_top_name">
                  {guest.name}
                </p>
                <p className="guest_list_filter_item_container_right_top_time">
                  {guest.registerTime}
                </p>
              </div>
              <p className="guest_list_filter_item_container_right_email">
                {guest.email}
              </p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      {glance()}
      {guestOptions()}
      <div className="guest_options_container_line" />
      {guestList()}
      {guestListFilter()}
    </div>
  );
}
