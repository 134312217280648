import React, { useEffect, useState } from "react";
import "./Modal.css";

interface ModalProps {
  isVisible: boolean;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isVisible, children }) => {
  const [isTransitioning, setIsTransitioning] = useState(false);
  useEffect(() => {
    if (isVisible) {
      setIsTransitioning(true); // Start transition
    } else {
      // Ensure the modal is removed after transition ends
      const timeoutId = setTimeout(() => {
        setIsTransitioning(false);
      }, 500); // Match the duration of the transition

      return () => clearTimeout(timeoutId);
    }
  }, [isVisible]);
  if (!isTransitioning && !isVisible) return null;

  return (
    <div className={`modal-overlay ${isVisible ? "open" : ""}`}>
      <div className="modal-content">{children}</div>
    </div>
  );
};

export default Modal;
