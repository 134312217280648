// import EventHome from "./screens/event_page/EventHome";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import EventDetail from "./screens/event_detail_page/EventDetail";
// import Event from "./screens/event_page_inside/Event";
// import Creator from "./screens/creator/Creator";
import Thanks from "./screens/thanks/Thanks";
import HomeV2 from "./screens/home_v2/HomeV2";
import Discover from "./screens/discover/Discover";
import Event1 from "./screens/event/Event";
import ExploreCity from "./screens/explore_city/ExploreCity";
import CreatorV2 from "./screens/creator/CreatorV2";
import SignupCreator from "./screens/auth/SignupCreator";
import SignupExplorer from "./screens/auth/SignupExplorer";
import LoginCreator from "./screens/auth/LoginCreator";
import LoginExplorer from "./screens/auth/LoginExplorer";
import PrivacyPolicy from "./screens/policy/PrivacyPolicy";
import Tnc from "./screens/policy/Tnc";
import DataDeletionPolicy from "./screens/policy/DataDeletionPolicy";
import { init } from "@amplitude/analytics-browser";
import { useEffect } from "react";
import EventV2 from "./screens/eventv2/EventV2";
import CreateEvent from "./screens/CreateEvent/CreateEvent";

function App() {
  useEffect(() => {
    init("7746cf291a15cca4af42adbd5c67baf5");
  }, []);

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<HomeV2 />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/tnc" element={<Tnc />} />
          <Route
            path="/data-deletion-policy"
            element={<DataDeletionPolicy />}
          />
          <Route path="/discover" element={<Discover />} />
          <Route path="/discover/:id" element={<ExploreCity />} />
          <Route path="/event/:id" element={<Event1 />} />
          <Route path="/creator/:id" element={<CreatorV2 />} />
          <Route path="/thanks" element={<Thanks />} />
          <Route path="/signup-creator" element={<SignupCreator />} />
          <Route path="/signup-explorer" element={<SignupExplorer />} />
          <Route path="/login-creator" element={<LoginCreator />} />
          <Route path="/login-explorer" element={<LoginExplorer />} />
          <Route path="/eventv2" element={<EventV2 />} />
          <Route path="/create-event" element={<CreateEvent />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
