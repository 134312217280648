import React from "react";
import "./CreateEvent.css";
import PlusIcon from "../../resources/images/create_event/plus_icon.svg";
import TripleDotIcon from "../../resources/images/create_event/triple_dot_icon.svg";
import Check1Icon from "../../resources/images/create_event/check_1.svg";
import Check2Icon from "../../resources/images/create_event/check_2.svg";
import Check3Icon from "../../resources/images/create_event/check_3.svg";
import ContactIcon from "../../resources/images/create_event/contact_icon.svg";
import MailIcon from "../../resources/images/create_event/mail_icon.svg";
import PhoneIcon from "../../resources/images/create_event/mobile_icon.svg";
import PersonIcon from "../../resources/images/create_event/person_icon.svg";
import CustomIcon from "../../resources/images/create_event/custom_icon.svg";

export default function Registration() {
  const tickets = () => {
    const ticketType = [
      {
        id: "ticket-1",
        name: "Standard",
        type: "Free",
        tag: "Required Approval",
        number_of_registrations: 1,
      },
      {
        id: "ticket-2",
        name: "Standard",
        type: "Free",
        tag: "Required Approval",
        number_of_registrations: 1,
      },
    ];
    return (
      <div className="tickets_container">
        <div className="tickets_container_header">
          <p className="tickets_container_header_text">Tickets</p>
          <div className="tickets_container_header_button">
            <img src={PlusIcon} alt="plus_icon" />
            <p className="tickets_container_header_button_text">
              New Ticket Type
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            marginTop: "12px",
            marginBottom: "18px",
          }}
        >
          {ticketType.map((ticket) => {
            return (
              <div className="ticket_type_container">
                <div className="ticket_type_container_left">
                  <p className="ticket_type_container_name">{ticket.name}</p>
                  <p className="ticket_type_container_type">{ticket.type}</p>
                </div>
                {/* <div className="ticket_type_container_tag_container">
                  <p className="ticket_type_container_tag">{ticket.tag}</p>
                </div> */}
                <div className="ticket_type_container_left">
                  <p className="ticket_type_container_number_of_registrations">
                    {ticket.number_of_registrations} Registrations
                  </p>
                  <img src={TripleDotIcon} alt="triple_dot_icon" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const registrationEmail = () => {
    const registrationEmailCards = [
      {
        icon: Check1Icon,
        title: "Pending Approval/ Waiting List",
      },
      {
        icon: Check2Icon,
        title: "Going",
      },
      {
        icon: Check3Icon,
        title: "Declined",
      },
    ];
    return (
      <div className="registration_email_container">
        <p className="registration_email_container_header">
          Registration Email
        </p>
        <p className="registration_email_container_subheading">
          Customise the emails sent when a guest registers for the event and for
          when you approve or decline their registration.
        </p>
        <div className="registration_email_cards_container">
          {registrationEmailCards.map((card) => {
            return (
              <div className="registration_email_cards">
                <div className="registration_email_cards_top">
                  <img
                    src={card.icon}
                    alt="card_icon"
                    style={{ marginLeft: 6 }}
                  />
                  <div className="fat_line_1" />
                  <div className="fat_line_2" />
                </div>
                <div className="registration_email_cards_bottom">
                  <p className="registration_email_cards_title">{card.title}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const registrationQueries = () => {
    const questions = [
      {
        icon: PersonIcon,
        title: "Name",
        tag: "Required",
      },
      {
        icon: MailIcon,
        title: "Email",
        tag: "Required",
      },
      {
        icon: PhoneIcon,
        title: "Phone Number",
        tag: "",
        type: "dropdown",
      },
    ];
    return (
      <div className="registration_queries_container">
        <p className="registration_email_container_header">
          Registration Questions
        </p>
        <p className="registration_email_container_subheading">
          We will ask guests the following questions when they register for the
          event.
        </p>
        <div className="registration_queries_personal_container">
          <img src={ContactIcon} alt="contact_icon" />
          <p className="registration_queries_personal_container_text">
            Personal Information
          </p>
        </div>
        <div className="registration_queries_questions_container">
          {questions.map((question, index) => {
            return (
              <div className="registration_queries_questions">
                <img src={question.icon} alt="question_icon" />
                <div
                  className="registration_queries_questions_right"
                  style={{
                    borderBottom:
                      index === questions.length - 1
                        ? "none"
                        : "0.333px solid var(--Separators-Non-opaque, rgba(84, 84, 86, 0.34))",
                  }}
                >
                  <p className="registration_queries_questions_title">
                    {question.title}
                  </p>
                  <p className="registration_queries_questions_tag">
                    {question.tag}
                  </p>
                  {question.type === "dropdown" && (
                    <select className="registration_queries_questions_dropdown registration_queries_questions_dropdown_option">
                      <option className="registration_queries_questions_dropdown_option">
                        On
                      </option>
                      <option className="registration_queries_questions_dropdown_option">
                        Off
                      </option>
                    </select>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const customQuestions = () => {
    return (
      <div>
        <div className="registration_queries_personal_container">
          <img src={CustomIcon} alt="custom_icon" />
          <p className="registration_queries_personal_container_text">
            Custom Questions
          </p>
        </div>
        <div
          className="tickets_container_header_button"
          style={{ marginTop: 15 }}
        >
          <img src={PlusIcon} alt="plus_icon" />
          <p className="tickets_container_header_button_text">Add Question</p>
        </div>
      </div>
    );
  };
  return (
    <div>
      {tickets()}
      <div className="guest_options_container_line" />
      {registrationEmail()}
      <div className="guest_options_container_line" style={{ marginTop: 32 }} />
      {registrationQueries()}
      {customQuestions()}
    </div>
  );
}
