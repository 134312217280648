import React, { useState, useEffect } from "react";
import Navbar from "../../commons/Navbar";
import { useMediaQuery } from "@mui/material";
import Back from "../../resources/images/back.svg";
import "./Event.css";
import MapPoint from "../../resources/images/Map Point.svg";
import Calendar from "../../resources/images/calendar-2.svg";
import Golf from "../../resources/images/golf.svg";
import User from "../../resources/images/user.svg";
import Setting from "../../resources/images/setttings.svg";
import Itinerary from "../../resources/images/itinerary.svg";
import Policy from "../../resources/images/policy.svg";
// import Participants from "../../resources/images/participant.svg";
import Spa from "../../resources/images/spa.svg";
import Trek from "../../resources/images/trek.svg";
import Surf from "../../resources/images/surf.svg";
import Fire from "../../resources/images/fire.svg";
import ArrowLeftUp from "../../resources/images/arrowleftup.svg";
import Aman from "../../resources/images/aman.webp";
import Samarth from "../../resources/images/samarth.webp";
import Ellipse from "../../resources/images/top_ellipse_1.svg";
import TickCircle from "../../resources/images/tick-circle.svg";
import BackItnDate from "../../resources/images/back-itn-date.svg";
import { ReactComponent as Calendar2 } from "../../resources/images/calendar-2.svg";
import { ReactComponent as List } from "../../resources/images/list.svg";
import Share from "../../resources/images/share.svg";
import Clock from "../../resources/images/clock.svg";
import ItineraryImage from "../../resources/images/test-itinerary.png";
import ItineraryImage2 from "../../resources/images/test-itinerary1.png";
import ItineraryImage3 from "../../resources/images/test-itinerary2.png";
import Stretching from "../../resources/images/stretching.svg";
import CheckGreen from "../../resources/images/check-green.svg";
import CheckRed from "../../resources/images/check-red.svg";
import EmptyStar from "../../resources/images/empty-star.svg";
import FilledStar from "../../resources/images/filled-star.svg";
import TestReview from "../../resources/images/test-review1.svg";
import Location from "../../resources/images/location2.svg";
import ReviewIcon1 from "../../resources/images/review-icon-1.svg";
import ReviewIcon2 from "../../resources/images/review-icon-2.svg";
import ReviewIcon3 from "../../resources/images/review-icon-3.svg";
import FillStar from "../../resources/images/review-icon-4.svg";
import ReviewIcon5 from "../../resources/images/value-icon.svg";
import EmtStar from "../../resources/images/emt-star.svg";
import { otherData, spitiData } from "./helper";
import { useParams } from "react-router-dom";
import "./EventMobile.css";
import { logEvent } from "@amplitude/analytics-browser";

export default function Event() {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [selected, setSelected] = useState(0);
  const [selectedItnDay, setSelectedItnDay] = useState(0);
  const [selectedItnType, setSelectedItnType] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const { id } = useParams();
  const data = id === "spiti" ? spitiData : otherData;
  const backButton = () => {
    return (
      <div
        className="back-button-container"
        style={{
          marginTop: isMobile ? 20 : 24,
          marginLeft: isMobile ? 4 : 24,
          cursor: "pointer",
        }}
        onClick={() => {
          window.open("/discover/" + id, "_self");
        }}
      >
        <img src={Back} alt="Back" style={{ opacity: 0.48 }} />
        <p className="back-text">Back</p>
      </div>
    );
  };
  const headingSection = () => {
    const details = [
      {
        id: "dt-01",
        icon: Golf,
        text: "Team Building",
      },
      {
        id: "dt-02",
        icon: User,
        text: "25 Members",
      },
      {
        id: "dt-03",
        icon: Calendar,
        text: "7 Days, 6 Nights",
      },
    ];
    return (
      <div>
        <div className="heading-container-div">
          <p className="heading-event-text">{data.title}</p>
          {!isMobile && (
            <div style={{ display: "flex", gap: 14 }}>
              <div className="header-label-div">
                <img src={MapPoint} alt="location" />
                <p className="header-label-text">{data.location}</p>
              </div>
              <div className="header-label-div">
                <img src={Calendar} alt="date" />
                <p className="header-label-text">7 Days, 8 Nights</p>
              </div>
            </div>
          )}
        </div>
        <div className="heading-subdiv">
          {details.map((i) => (
            <div className="heading-subdiv-div" key={i.id}>
              <img src={i.icon} alt="golf" />
              <p className="header-label-text">{i.text}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const heroImages = () => {
    const imgArr = data.heroImgs;
    return (
      <div className="hero-images-container">
        <img
          className="hero-img-primary"
          alt="cover"
          src={imgArr[0]}
          style={{ flexGrow: 318 }}
        />
        <div className="hero-image-secondary-container">
          <img className="hero-img-secondary" alt="cover" src={imgArr[1]} />
          <img className="hero-img-secondary" alt="cover" src={imgArr[2]} />
        </div>
        <img
          className="hero-img-primary"
          alt="cover"
          src={imgArr[3]}
          style={{ flexGrow: 394 }}
        />
      </div>
    );
  };

  const pageSections = () => {
    const handleSelected = (i: React.SetStateAction<number>) => {
      setIsAnimating(true);
      setTimeout(() => {
        setSelected(i);
        setIsAnimating(false);
      }, 150); // Half of the transition duration
    };
    return (
      <div className="pageSection-container">
        <div
          className={
            selected === 0
              ? "pageSection-container-div"
              : "pageSection-container-div-unselected"
          }
          onClick={() => handleSelected(0)}
        >
          <img
            src={Setting}
            alt="overview"
            style={{
              width: isMobile ? 12 : 24,
              height: isMobile ? 12 : 24,
            }}
          />
          <p
            className={
              selected === 0
                ? "pageSection-text-selected"
                : "pageSection-text-unselected"
            }
          >
            Overiew
          </p>
        </div>
        <div
          className={
            selected === 1
              ? "pageSection-container-div"
              : "pageSection-container-div-unselected"
          }
          onClick={() => handleSelected(1)}
        >
          <img
            src={Itinerary}
            alt="Itinerary"
            style={{
              width: isMobile ? 12 : 24,
              height: isMobile ? 12 : 24,
            }}
          />
          <p
            className={
              selected === 1
                ? "pageSection-text-selected"
                : "pageSection-text-unselected"
            }
          >
            Itinerary
          </p>
        </div>
        <div
          className={
            selected === 2
              ? "pageSection-container-div"
              : "pageSection-container-div-unselected"
          }
          onClick={() => handleSelected(2)}
        >
          <img
            src={Policy}
            alt="Policy"
            style={{
              width: isMobile ? 12 : 24,
              height: isMobile ? 12 : 24,
            }}
          />
          <p
            className={
              selected === 2
                ? "pageSection-text-selected"
                : "pageSection-text-unselected"
            }
          >
            Policies
          </p>
        </div>
        {/* <div
          className={
            selected === 3
              ? "pageSection-container-div"
              : "pageSection-container-div-unselected"
          }
          onClick={() => handleSelected(3)}
        >
          <img src={Participants} alt="Participants" />
          <p
            className={
              selected === 3
                ? "pageSection-text-selected"
                : "pageSection-text-unselected"
            }
          >
            Participants
          </p>
        </div> */}
      </div>
    );
  };

  const line = () => {
    return <div className="line-event-div" />;
  };
  const headerSection = (header: string) => {
    return <p className="event-page-header-text">{header}</p>;
  };
  const infoSection = () => {
    const itineraryAbout = () => {
      return (
        <div className="itinerary-about-section">
          {headerSection("About this space")}
          <p className="itinerary-about-section-subtext">{data.description}</p>
          <div className="show-more-container">
            <p className="show-more-text">Show more</p>
            <img
              src={Back}
              alt="show-more"
              style={{ transform: "rotate(180deg)" }}
            />
          </div>
          <div className="itinerary-label-container">
            <div className="itinerary-label-container-div">
              <img src={Spa} alt="Spa" className="itinerary-label-icon" />
              <p className="itinerary-label-text">Great Stay</p>
            </div>
            <div className="itinerary-label-container-div">
              <img src={Surf} alt="surf" className="itinerary-label-icon" />
              <p className="itinerary-label-text">Meditation</p>
            </div>
            <div className="itinerary-label-container-div">
              <img src={Trek} alt="trek" className="itinerary-label-icon" />
              <p className="itinerary-label-text">Spiritual Journey</p>
            </div>
          </div>
        </div>
      );
    };

    const itineraryOffer = () => {
      return (
        <div style={{ marginTop: 56 }}>
          {headerSection("What this place offers")}
          <div className="itinerary-offer-container">
            <div className="itinerary-offer-container-div">
              <img src={Fire} alt="Trending" />
              <p className="offer-card-heading">Dedicated Workspace</p>
              <p className="offer-card-text">
                A room with wifi that’s well suited for working.
              </p>
            </div>
            <div className="itinerary-offer-container-div">
              <img src={Fire} alt="Trending" />
              <p className="offer-card-heading">Great location</p>
              <p className="offer-card-text">
                90% of recent guests gave the location a 5-star rating.
              </p>
            </div>
          </div>
          <div
            className="itinerary-offer-container"
            style={{ marginTop: isMobile ? 16 : 0 }}
          >
            <div className="itinerary-offer-container-div">
              <img src={Fire} alt="Trending" />
              <p className="offer-card-heading">Meditation Space</p>
              <p className="offer-card-text">
                A room with wifi that’s well suited for working.
              </p>
            </div>
            <div className="itinerary-offer-container-div itinerary-offer-show-more">
              <img
                src={ArrowLeftUp}
                alt="arrow left up"
                className="arrow-left-up-itinerary"
              />
              <img src={Fire} alt="Trending" />
              <p className="offer-card-heading">Show more</p>
              <p className="offer-card-text">See all 50+ amenities</p>
            </div>
          </div>
        </div>
      );
    };

    const itineraryWhere = () => {
      return (
        <div style={{ marginTop: 60 }}>
          <div className="itinerary-where-top-heading">
            {headerSection("Where you’ll be")}
            <div className="header-label-div">
              <img src={MapPoint} alt="location" />
              <p className="header-label-text">{data.location}</p>
            </div>
          </div>
          <img src={data.map} alt="map" className="map-wrapper" />
        </div>
      );
    };

    const itineraryQuestions = () => {
      return (
        <div style={{ marginTop: 56, paddingBottom: 56 }}>
          {headerSection("Still have a question?")}
          <p className="itinerary-question-subtext">
            If you cannot find answer to your question in our FAQ, you can
            always contact us. We will answer to you shortly!
          </p>
          <button className="itinerary-question-button">
            Contact Us
            <img
              src={Back}
              alt="contact-icon"
              style={{ transform: "rotate(180deg)" }}
            />
          </button>
        </div>
      );
    };
    return (
      <div>
        {itineraryAbout()}
        {itineraryOffer()}
        {itineraryWhere()}
        {itineraryQuestions()}
      </div>
    );
  };

  const creatorSection = () => {
    return (
      <div className="creator-event-page-container">
        <p className="creator-section-heading">Top Creators</p>
        <img src={Aman} alt="creator" className="main-creator-image-event" />
        <div className="ellipse-creator-container-event">
          <img
            src={Ellipse}
            alt="creator"
            className="ellipse-creator-image"
            style={{ zIndex: 3 }}
          />
          <img
            src={Aman}
            alt="creator"
            className="ellipse-creator-image"
            style={{ marginLeft: -15, zIndex: 2 }}
          />
          <img
            src={Samarth}
            alt="creator"
            className="ellipse-creator-image"
            style={{ marginLeft: -15, zIndex: 1 }}
          />
        </div>
        <p className="creator-div-subheading">{data.title}</p>
        <div className="creator-event-info-container">
          <div className="creator-event-info-div">
            <img src={MapPoint} alt="location" />
            <p className="header-label-text">{data.location}</p>
          </div>
          <div className="creator-event-info-div">
            <img src={Golf} alt="Golf" />
            <p className="header-label-text">Team Building</p>
          </div>
          <div className="creator-event-info-div">
            <img src={Calendar} alt="Calendar" />
            <p className="header-label-text">
              Fri, 10 Jan 2024 - Sat, 18 Jan 2024
            </p>
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <p
            className="creator-div-subheading"
            style={{ fontSize: 16, marginTop: 25 }}
          >
            Activites
          </p>
          <div className="creator-activity-container">
            <div className="creator-activity-container-div">
              <img src={TickCircle} alt="tick" />
              <p className="creator-activity-container-text">
                Meditation with Samarth
              </p>
            </div>
            <div className="creator-activity-container-div">
              <img src={TickCircle} alt="tick" />
              <p className="creator-activity-container-text">
                Design workshop with Aman
              </p>
            </div>
          </div>
          <p
            className="creator-div-subheading"
            style={{ fontSize: 16, marginTop: 32 }}
          >
            Workshops
          </p>
          <div className="creator-activity-container">
            <div className="creator-activity-container-div">
              <img src={TickCircle} alt="tick" />
              <p className="creator-activity-container-text">
                Design workshop with Aman
              </p>
            </div>
            <div className="creator-activity-container-div">
              <img src={TickCircle} alt="tick" />
              <p className="creator-activity-container-text">
                Meditation workshop with Samarth
              </p>
            </div>
          </div>
          <div className="button-container">
            <button
              className="check-itinerary-button"
              onClick={() => {
                setSelected(1);
              }}
            >
              Check Itinerary{" "}
              <img
                src={Back}
                alt="contact-icon"
                style={{ transform: "rotate(180deg)" }}
              />
            </button>
          </div>
        </div>
      </div>
    );
  };

  const infoSection2 = () => {
    const sectionTop = () => {
      return (
        <div className="section-top-tinerary">
          <div className="section-top-tinerary-div">
            <img src={Fire} alt="trending" />
            <p className="section-top-tinerary-text">Thing to expect</p>
            <p className="section-top-tinerary-subtext">
              Relish a unique blend of relaxation and luxury on this exotic
              vacation at {data.location}.
            </p>
          </div>
          <div className="section-top-tinerary-div">
            <img src={Fire} alt="trending" />
            <p className="section-top-tinerary-text">Things you’ll love</p>
            <p className="section-top-tinerary-subtext">
              Relish a unique blend of relaxation and luxury on this exotic
              vacation at {data.location}.
            </p>
          </div>
        </div>
      );
    };

    const dateSection = () => {
      const itDays = [
        {
          date: 10,
          month: "Jan",
          title: "Arrival",
          day: "Thursday",
        },
        {
          date: 11,
          month: "Jan",
          title: "Team Building",
          day: "Friday",
        },
        {
          date: 12,
          month: "Jan",
          title: "Departure",
          day: "Saturday",
        },
      ];
      return (
        <div
          style={{
            paddingTop: 40,
            top: 55,
            position: isMobile ? "static" : "sticky",
            height: "fit-content",
          }}
        >
          <p className="day-plan-text">Day Plan</p>
          <div className="itinerary-date-section-container">
            {itDays.map((i, index) => (
              <div
                className="it-days-container"
                key={i.date}
                style={{
                  background:
                    selectedItnDay === index ? `url(${BackItnDate})` : "",
                }}
                onClick={() => setSelectedItnDay(index)}
              >
                <div style={{ width: 33 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <p className="it-day-text">{i.month}</p>
                    <p className="it-day-subtext">{i.date}</p>
                  </div>
                </div>

                <div
                  style={{
                    height: 26.5,
                    background: "rgba(33, 33, 33, 0.32)",
                    width: 1,
                  }}
                />

                <div>
                  <p className="it-title-text">{i.title}</p>
                  <p className="it-title-subtext">{i.day}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    };

    const itinerarySection = () => {
      const itineraryLabels = (
        icon: string | undefined,
        text:
          | string
          | number
          | boolean
          | React.ReactElement<any, string | React.JSXElementConstructor<any>>
          | Iterable<React.ReactNode>
          | null
          | undefined
      ) => {
        return (
          <div className="itinerary-section-label">
            <img src={icon} alt="location" />
            <p className="itinerary-section-label-text">{text}</p>
          </div>
        );
      };
      const detailArr = [
        {
          icon: MapPoint,
          text: "1.9 km from lake",
        },
        {
          icon: Calendar,
          text: "5:00 PM",
        },
        {
          icon: Clock,
          text: "1 hour",
        },
        {
          icon: Clock,
          text: "1 hour",
        },
      ];
      const itineraryDetail = (
        image: string | undefined,
        heading:
          | string
          | number
          | boolean
          | React.ReactElement<any, string | React.JSXElementConstructor<any>>
          | Iterable<React.ReactNode>
          | null
          | undefined,
        labelText:
          | string
          | number
          | boolean
          | React.ReactElement<any, string | React.JSXElementConstructor<any>>
          | Iterable<React.ReactNode>
          | null
          | undefined,
        subtext:
          | string
          | number
          | boolean
          | React.ReactElement<any, string | React.JSXElementConstructor<any>>
          | Iterable<React.ReactNode>
          | null
          | undefined,
        detailArray:
          | {
              icon: string | undefined;
              text:
                | string
                | number
                | boolean
                | React.ReactElement<
                    any,
                    string | React.JSXElementConstructor<any>
                  >
                | Iterable<React.ReactNode>
                | React.ReactPortal
                | null
                | undefined;
            }[]
      ) => {
        return (
          <div className="itinerary-detail-wrapper">
            <img
              src={image}
              alt="itinerary"
              className="itinerary-detail-image"
            />
            <div className="itinerary-detail-text-wrapper">
              <div style={{ display: "flex", alignItems: "center", gap: 12 }}>
                <p className="itinerary-detail-heading">{heading}</p>
                {labelText && itineraryLabels(Clock, labelText)}
              </div>
              <p className="itinerary-detail-subtext">{subtext}</p>
              <div className="itinerary-detail-subtext-wrapper">
                {detailArray.map(
                  (i: {
                    icon: string | undefined;
                    text:
                      | string
                      | number
                      | boolean
                      | React.ReactElement<
                          any,
                          string | React.JSXElementConstructor<any>
                        >
                      | Iterable<React.ReactNode>
                      | React.ReactPortal
                      | null
                      | undefined;
                  }) => (
                    <div
                      style={{ display: "flex", alignItems: "center", gap: 4 }}
                    >
                      <img src={i.icon} alt="icon" />
                      <p className="itinerary-detail-subtext">{i.text}</p>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        );
      };
      return (
        <div className="itinerary-section-subcontainer">
          <div className="itinerary-section-subcontainer-top-div">
            <div className="itinerary-section-subcontainer-top-div-left">
              <button
                className={`itinerary-list-view-button ${
                  selectedItnType === 0
                    ? "itinerary-list-view-button-selected"
                    : ""
                }`}
                onClick={() => setSelectedItnType(0)}
              >
                <List
                  className={`calendar-icon ${
                    selectedItnType === 0 ? "calendar-icon-selected" : ""
                  }`}
                />
                List
              </button>
              <button
                className={`itinerary-list-view-button ${
                  selectedItnType === 1
                    ? "itinerary-list-view-button-selected"
                    : ""
                }`}
                onClick={() => setSelectedItnType(1)}
              >
                <Calendar2
                  className={`calendar-icon ${
                    selectedItnType === 1 ? "calendar-icon-selected" : ""
                  }`}
                />
                Calendar
              </button>
            </div>
            <div className="itinerary-section-subcontainer-top-div-right">
              <img src={Share} alt="share" />
              <p className="share-text">Share Itinerary</p>
            </div>
          </div>
          <div className="itinerary-section-labels-wrapper">
            {itineraryLabels(MapPoint, "Location")}
            {itineraryLabels(Clock, "Check in time: 12 PM")}
          </div>
          <div
            style={{ margin: isMobile ? "24px 22px" : "24px 22px 52px 72px" }}
          >
            {itineraryDetail(
              ItineraryImage,
              "Hotel",
              "Pending",
              `Relish a unique blend of relaxation and luxury on this exotic vacation at ${data.location}.`,
              detailArr
            )}
            <div className="itinerary-section-divider" />
            <div style={{ margin: "24px 0px" }}>
              {itineraryLabels(Stretching, "Activities & Workshops")}
            </div>
            {itineraryDetail(
              ItineraryImage2,
              "Outdoor fun activities",
              "",
              `Relish a unique blend of relaxation and luxury on this exotic vacation at ${data.location}.`,
              detailArr
            )}
            <div style={{ marginTop: 24 }}>
              {itineraryDetail(
                ItineraryImage3,
                "Design team workshop",
                "Pending",
                `Relish a unique blend of relaxation and luxury on this exotic vacation at ${data.location}.`,
                detailArr
              )}
            </div>
          </div>
        </div>
      );
    };
    return (
      <div>
        {sectionTop()}
        <div className="itinerary-section-container">
          {dateSection()}
          {itinerarySection()}
        </div>
      </div>
    );
  };

  const infoSection3 = () => {
    const tncs = [
      {
        icon: CheckGreen,
        text: "Passport, Driving License, Govt. ID and Aadhar are accepted as ID proof(s)",
      },
      {
        icon: CheckGreen,
        text: "Pets are not allowed.",
      },
      {
        icon: CheckGreen,
        text: "Outside food is not allowed.",
      },
      {
        icon: CheckGreen,
        text: "Groups with only male guests are not allowed at this property",
      },
      {
        icon: CheckGreen,
        text: "Passport, Driving License, Govt. ID and Aadhar are accepted as ID proof(s)",
      },
      {
        icon: CheckRed,
        text: "Smoking not allowed.",
      },
      {
        icon: CheckRed,
        text: "Alcohol is not allowed.",
      },
      {
        icon: CheckRed,
        text: "No party or event is allowed.",
      },
    ];
    return (
      <div className="policies-container">
        <p className="policies-heading">Terms & Conditions</p>
        <p className="policies-subheading">{data.description}</p>
        <div
          className="tncs-container"
          style={{ marginBottom: isMobile ? 16 : 0 }}
        >
          {tncs.map((i) => (
            <div className="tncs-container-div" key={i.text}>
              <img src={i.icon} alt="icon" />
              <p className="tncs-container-text">{i.text}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const tripReviews = () => {
    const reviewIconArr = [
      {
        icon: ReviewIcon2,
        rating: 4.9,
        title: "Cleanliness",
      },
      {
        icon: ReviewIcon1,
        rating: 4.9,
        title: "Accuracy",
      },
      {
        icon: ReviewIcon3,
        rating: 4.9,
        title: "Checkin",
      },
      {
        icon: Location,
        rating: 4.9,
        title: "Location",
      },
      {
        icon: ReviewIcon5,
        rating: 4.9,
        title: "Value",
      },
    ];

    const reviewArr = [
      {
        image: TestReview,
        star: 4,
        name: "Genellia",
        text: "I recently stayed at a charming guesthouse in Chandigarh, and it was an absolute delight. The ambiance was cozy, and the attention to detail in the room decor was impressive.",
        date: "September 22, 2023",
      },
    ];
    return (
      <div>
        {headerSection("Reviews (24)")}
        <div className="overview-review-container">
          <div className="overview-review-container-div-left">
            <p className="overview-review-rating-text">4.1</p>
            <div className="overview-review-rating-div">
              <img src={FilledStar} alt="filled-star" />
              <img src={FilledStar} alt="filled-star" />
              <img src={FilledStar} alt="filled-star" />
              <img src={FilledStar} alt="filled-star" />
              <img src={EmptyStar} alt="empty-star" />
            </div>
            <p className="overview-review-rating-subtext">24 ratings</p>
          </div>
          <div className="overview-review-container-div-right">
            {reviewIconArr.map((i) => (
              <div className="overview-review-container-div-right-div">
                <div className="overview-review-container-div-right-div-icon">
                  <img src={i.icon} alt="review-icon" />
                </div>
                <p className="overview-review-container-div-right-div-text">
                  {i.title}
                </p>
                <p className="overview-review-container-div-right-div-subtext">
                  {i.rating}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="detail-review-container">
          {[
            ...reviewArr,
            ...reviewArr,
            ...reviewArr,
            ...reviewArr,
            ...reviewArr,
            ...reviewArr,
          ].map((i) => (
            <div className="detail-review-container-div">
              <div className="detail-review-container-div-image-wrapper">
                <img
                  src={i.image}
                  alt="review"
                  className="detail-review-container-div-image"
                />
                <p className="detail-review-container-div-name">{i.name}</p>
              </div>
              <div className="detail-review-container-div-date-wrapper">
                {i.star && (
                  <div className="detail-review-container-div-star-wrapper">
                    <img src={FillStar} alt="filled-star" />
                    <img src={FillStar} alt="filled-star" />
                    <img src={FillStar} alt="filled-star" />
                    <img src={FillStar} alt="filled-star" />
                    <img
                      src={EmtStar}
                      alt="empty-star"
                      style={{ opacity: 0.24 }}
                    />
                  </div>
                )}
                <p className="detail-review-container-div-date">{i.date}</p>
              </div>
              <p className="detail-review-container-div-text">{i.text}</p>
              <p className="detail-review-container-div-read-more">Show more</p>
            </div>
          ))}
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button className="show-more-button">Load more reviews</button>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Navbar isMobile={isMobile} />
      {backButton()}
      <div
        style={{
          paddingLeft: isMobile ? 11 : 44,
          marginTop: 24,
          paddingRight: isMobile ? 11 : 44,
        }}
      >
        {headingSection()}
        {heroImages()}
        {pageSections()}
        {line()}
        <div
          style={{ display: "flex", gap: 75 }}
          className={`section-content ${
            isAnimating ? "section-content-entering" : "section-content-visible"
          }`}
        >
          {/* {selected === 0 && infoSection()}
          {selected === 1 && infoSection2()}
          {selected === 2 && infoSection3()} */}
          {/* <div
            className={`section-content ${
              isAnimating
                ? "section-content-entering"
                : "section-content-visible"
            }`}
          > */}
          {selected === 0 && infoSection()}
          {selected === 1 && infoSection2()}
          {selected === 2 && infoSection3()}
          {/* </div> */}
          {selected !== 1 && !isMobile && creatorSection()}
        </div>
        {selected === 0 && tripReviews()}
      </div>
    </div>
  );
}
