import React, { useEffect } from "react";
import "./Navbar.css";
// import CreateArrow from "../resources/images/create-arrow.svg";
import Logo from "../resources/images/t-logo.svg";
import { logEvent } from "@amplitude/analytics-browser";
import axios from "axios";
export default function Navbar({
  isMobile,
  filter,
  button,
}: {
  isMobile: boolean;
  filter?: boolean;
  button?: string;
}) {
  const apiCall = async (API_KEY: string, API_SECRET: string) => {
    try {
      const response = await axios.get(
        "https://amplitude.com/api/2/events/list",
        {
          auth: {
            username: API_KEY,
            password: API_SECRET,
          },
        }
      );

      console.log("Amplitude Events:", response.data);
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error("Error fetching events:", error.message);
      } else {
        console.error("Error fetching events:", String(error));
      }
    }
  };
  useEffect(() => {
    const API_KEY = "7746cf291a15cca4af42adbd5c67baf5";
    const API_SECRET = "28eb1590a27ea98e6132387f56771d79";
    apiCall(API_KEY, API_SECRET);
  }, []);
  return (
    <div
      className="navbar-container"
      style={
        !filter
          ? {
              position: "sticky",
              top: "0",
              zIndex: "1000",
              backgroundColor: "white",
              // filter: filter ? "blur(1.2000000476837158px)" : "none",
            }
          : { backdropFilter: "blur(10px)" }
      }
    >
      <img
        src={Logo}
        alt="logo"
        onClick={() => {
          window.location.href = "/";
        }}
        style={{ cursor: "pointer" }}
      />
      <button
        className={"create-button"}
        onClick={() => {
          console.log("button clicked");
          logEvent("Button Clicked", {
            buttonName: "Subscribe",
            page: "Homepage",
          });
          // if (!button) {
          //   window.location.href = "https://tally.so/r/mV1Mgg";
          // }
        }}
      >
        <p className="create-button-text">{button ? "Book now" : "Create"}</p>
        {/* <img src={CreateArrow} alt="create-arrow" style={{ width: 33 }} /> */}
      </button>
    </div>
  );
}
