import React, { useState } from "react";
import "./CreateEvent.css";
import ArrowTitled from "../../resources/images/create_event/arrow_tilted.svg";
import OverView from "./OverView";
import Registration from "./Registration";
import Blasts from "./Blasts";
import Insights from "./Insights";
import More from "./More";
import Guests from "./Guests";

export default function CreateEvent() {
  const [selectedOption, setSelectedOption] = useState("Overview");

  const heading = () => {
    return (
      <div className="create_event_heading_container">
        <p className="create_event_heading_1">Personal</p>
        <div className="create_event_heading_2_container">
          <p className="create_event_heading_2">Founders Meetup</p>
          <div className="create_event_heading_2_container_arrow">
            <img
              src={ArrowTitled}
              alt="arrow_tilted"
              className="arrow_tilted"
            />
          </div>
        </div>
      </div>
    );
  };

  const optionButtons = () => {
    const handleOptionClick = (option: string) => {
      setSelectedOption(option);
    };

    const options = [
      "Overview",
      "Guests",
      "Registration",
      "Blasts",
      "Insights",
      "More",
    ];

    return (
      <div className="create_event_option_buttons_container">
        {options.map((option) => (
          <div
            className={
              selectedOption === option
                ? "create_event_option_buttons_container_button_selected"
                : "create_event_option_buttons_container_button"
            }
            onClick={() => handleOptionClick(option)}
          >
            {option}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="create_event_container">
      <div
        className="create_event_container"
        style={{ maxWidth: "800px", margin: "0px auto" }}
      >
        {heading()}
        {optionButtons()}
        <div
          style={{
            padding: "0px 20px 50px",
          }}
        >
          {selectedOption === "Overview" && <OverView />}
          {selectedOption === "Registration" && <Registration />}
          {selectedOption === "Blasts" && <Blasts />}
          {selectedOption === "Insights" && <Insights />}
          {selectedOption === "More" && <More />}
          {selectedOption === "Guests" && <Guests />}
        </div>
      </div>
    </div>
  );
}
